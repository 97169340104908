.mobile-nav {
  /* Positioning */
  position: sticky;
  z-index: 5;
  /* Display & Box Model */
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  // height: 100;
  background-color: inherit;

  .mobile-nav-bar {
    height: 90px;
    width: 100%;
    background-color: white;
    // border: 1px solid red;
    box-shadow: 1px 1px 4px rgb(190, 190, 190);
    position: fixed;
  }

  .mobile-nav-menu {
    /* Positioning */
    position: fixed;
    z-index: 2;

    /* Display & Box Model */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 70vw;
    height: 100vh;

    /* Other */
    background-color: #f2f2f2;

    .mobile-nav-links-container {
      /* Display & Box Model */
      width: 50%;
      margin: 0 auto;
      margin-top: 120px;
      display: flex;
      flex-direction: column;

      .mobile-nav-link {
        /* Display & Box Model */
        padding: 0;
        margin: 0;
        margin-bottom: 32px;

        /* Other */
        font-size: 20px;
        letter-spacing: 0.5px;
        text-decoration: none;
        color: inherit;
        font-family: var(--font-family-primary);
        text-transform: uppercase;

        &.resume {
          /* Other */
          color: var(--color-primary);
        }
      }
    }
  }

  .shadow {
    /* Other */
    box-shadow: 0 4px 4px 5px rgba(0, 0, 0, 0.25);
  }

  .menu-icon {
    /* Positioning */
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 3;
  }
}
