@import "../../design-tokens/scss-variables";
/* Mobile Variables */
$widthBubbleMobile: 80px;
$floatDistanceMobile: -5px;

/* Medium Variables */
$widthBubbleMedium: 96px;
$floatDistanceMedium: -10px;

/* Breakpoint 1 Variables */
$widthBubbleB1: 106px;
$floatDistanceB1: -12px;

/* Breakpoint 2 Variables */
$widthBubbleB2: 120px;
$floatDistanceB2: -16px;

/* Larg Variables */
$widthBubbleLarge: 160px;
$floatDistanceLarge: -20px;

$floatAnimationDuration: 3s;

@mixin bubblePosition($widthBubble) {
  img {
    /* Positioning */
    position: absolute;
    /* Display & Box Model */
    width: $widthBubble * 0.5;
    height: $widthBubble * 0.5;
  }

  .SmallImage1 {
    /* Positioning */
    top: $widthBubble * -0.75;
    left: $widthBubble * -0.75;

    /* Other */
    animation-delay: $floatAnimationDuration * 1/6;
  }

  .SmallImage2 {
    /* Positioning */
    top: $widthBubble * -0.75;
    left: $widthBubble * 0.25;

    /* Other */
    animation-delay: $floatAnimationDuration * 2/6;
  }

  .SmallImage3 {
    /* Positioning */
    top: $widthBubble * 0.25;
    left: $widthBubble * 1;

    /* Other */
    animation-delay: $floatAnimationDuration * 3/6;
  }

  .SmallImage4 {
    /* Positioning */
    top: $widthBubble * 1.25;
    left: $widthBubble * 0.25;

    /* Other */
    animation-delay: $floatAnimationDuration * 4/6;
  }

  .SmallImage5 {
    /* Positioning */
    position: absolute;
    top: $widthBubble * 1.25;
    left: $widthBubble * -0.75;

    /* Display & Box Model */
    display: grid;
    place-items: center;
    border-radius: 50%;
    width: $widthBubble * 0.5;
    height: $widthBubble * 0.5;

    /* Other */
    animation-delay: $floatAnimationDuration * 5/6;
    background-color: white;

    img {
      width: 80%;
      height: 80%;
    }
  }

  .SmallImage6 {
    /* Positioning */
    position: absolute;
    top: $widthBubble * 0.25;
    left: $widthBubble * -1.5;

    /* Display & Box Model */
    display: grid;
    place-items: center;
    border-radius: 50%;
    width: $widthBubble * 0.5;
    height: $widthBubble * 0.5;

    /* Other */
    animation-delay: $floatAnimationDuration;
    background-color: white;

    img {
      width: 60%;
      height: 60%;
      border-radius: 0;
    }
  }
}

.SkillsSection {
  /* Display & Box Model */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;

  /* Other */
  background-color: var(--color-primary);

  .Container {
    /* Display & Box Model */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 600px;
    color: white;

    .Column {
      /* Display & Box Model */
      display: flex;
      flex-direction: column;
      // height: 100%;

      .Title {
        /* Display & Box Model */
        margin-bottom: $titlePaddingBottomMobile;

        /* Other */
        color: white;
        text-align: center;
        text-align: left;
      }

      .Description {
        margin-bottom: $titlePaddingBottomMobile;
      }

      .BioSkillsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 100px;
      }

      .MainImageContainer {
        /* Display & Box Model */
        width: $widthBubbleMobile;
        height: $widthBubbleMobile;
        border-radius: 50%;
        margin-bottom: 60px;

        /* Other */
        animation: float $floatAnimationDuration ease-in-out infinite;

        img {
          /* Display & Box Model */
          width: 100%;
          height: 100%;
        }
      }

      .SmallImageContainer {
        /* Positioning */
        position: absolute;

        img {
          /* Display & Box Model */
          width: $widthBubbleMobile * 0.5;
          height: $widthBubbleMobile * 0.5;
          border-radius: 50%;
          background-color: white;
          object-fit: scale-down;

          /* Other */
          animation: float $floatAnimationDuration ease-in-out infinite;
          transform: translatey(0px);
          box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        }
        .SmallImage5 {
          /* Other */
          animation: float $floatAnimationDuration ease-in-out infinite;
          transform: translatey(0px);
          box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);

          img {
            /* Other */
            animation: none;
            transform: translatey(0px);
            box-shadow: none;
          }
        }

        .SmallImage6 {
          /* Other */
          animation: float $floatAnimationDuration ease-in-out infinite;
          transform: translatey(0px);
          box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);

          img {
            /* Other */
            animation: none;
            transform: translatey(0px);
            box-shadow: none;
          }
        }

        img {
          /* Positioning */
          position: absolute;
          /* Display & Box Model */
          width: $widthBubbleMobile * 0.5;
          height: $widthBubbleMobile * 0.5;
        }

        .SmallImage1 {
          /* Positioning */
          top: $widthBubbleMobile * -0.75;
          left: $widthBubbleMobile * -0.25;

          /* Other */
          animation-delay: $floatAnimationDuration * 1/6;
        }

        .SmallImage2 {
          /* Positioning */
          top: $widthBubbleMobile * -0.75;
          left: $widthBubbleMobile * 0.75;

          /* Other */
          animation-delay: $floatAnimationDuration * 2/6;
        }

        .SmallImage3 {
          /* Positioning */
          top: $widthBubbleMobile * 0.25;
          left: $widthBubbleMobile * 1.5;

          /* Other */
          animation-delay: $floatAnimationDuration * 3/6;
        }

        .SmallImage4 {
          /* Positioning */
          top: $widthBubbleMobile * 1.25;
          left: $widthBubbleMobile * 0.75;

          /* Other */
          animation-delay: $floatAnimationDuration * 4/6;
        }

        .SmallImage5 {
          /* Positioning */
          position: absolute;
          top: $widthBubbleMobile * 1.25;
          left: $widthBubbleMobile * -0.25;

          /* Display & Box Model */
          display: grid;
          place-items: center;
          border-radius: 50%;
          width: $widthBubbleMobile * 0.5;
          height: $widthBubbleMobile * 0.5;

          /* Other */
          animation-delay: $floatAnimationDuration * 5/6;
          background-color: white;

          img {
            width: 80%;
            height: 80%;
          }
        }

        .SmallImage6 {
          /* Positioning */
          position: absolute;
          top: $widthBubbleMobile * 0.25;
          left: $widthBubbleMobile * -1;

          /* Display & Box Model */
          display: grid;
          place-items: center;
          border-radius: 50%;
          width: $widthBubbleMobile * 0.5;
          height: $widthBubbleMobile * 0.5;

          /* Other */
          animation-delay: $floatAnimationDuration;
          background-color: white;

          img {
            width: 60%;
            height: 60%;
            border-radius: 0;
          }
        }
      }
    }
    @keyframes float {
      0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
      }
      50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey($floatDistanceMobile);
      }
      100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
      }
    }
  }
}

@media (min-width: $screenWidthB1) {
  .SkillsSection {
    /* Display & Box Model */
    height: 700px;

    .Container {
      /* Display & Box Model */
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .Column {
        /* Display & Box Model */
        display: flex;
        flex-direction: column;
        align-items: center;

        .Bio {
          align-self: flex-start;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 70%;
          padding-top: 120px;

          .BioSkillsContainer {
            grid-template-columns: 1fr 1fr;
          }
        }

        .MainImageContainer {
          /* Display & Box Model */
          width: $widthBubbleMedium;
          height: $widthBubbleMedium;
          margin-right: $widthBubbleMedium;
          margin-bottom: 0;

        }

        .SmallImageContainer {
          /* Display & Box Model */
          padding-right: $widthBubbleMedium;

          @include bubblePosition($widthBubbleMedium);
        }
      }
    }

    @keyframes float {
      0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
      }
      50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey($floatDistanceMedium);
      }
      100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
      }
    }
  }
}

@media (min-width: $screenWidthB2) {
  .SkillsSection {
    .Container {
      /* Display & Box Model */

      .Column {
        .Title {
          margin-bottom: $titlePaddingBottomB2;
        }
        .Description{
          margin-bottom: $titlePaddingBottomB2;
        }
        .MainImageContainer {
          /* Display & Box Model */
          width: $widthBubbleB1;
          height: $widthBubbleB1;
        }

        .SmallImageContainer {
          /* Display & Box Model */
          @include bubblePosition($widthBubbleB1);
        }
      }
    }
    @keyframes float {
      0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
      }
      50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey($floatDistanceB1);
      }
      100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
      }
    }
  }
}

@media (min-width: $screenWidthB4) {
  .SkillsSection {
    .Container {
      .Column {
        .Title {
          margin-bottom: $titlePaddingBottomB4;
        }
        .Description{
          margin-bottom: $titlePaddingBottomB4;
        }
        .MainImageContainer {
          /* Display & Box Model */
          width: $widthBubbleB2;
          height: $widthBubbleB2;
        }

        .SmallImageContainer {
          /* Display & Box Model */

          @include bubblePosition($widthBubbleB2);
        }
      }
    }
    @keyframes float {
      0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
      }
      50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey($floatDistanceB2);
      }
      100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
      }
    }
  }
}

@media (min-width: $screenWidthB5) {
  .SkillsSection {
    height: 900px;
    .Container {
      .Column {
        .MainImageContainer {
          /* Display & Box Model */
          width: $widthBubbleLarge;
          height: $widthBubbleLarge;
        }

        .SmallImageContainer {
          @include bubblePosition($widthBubbleLarge);
        }
      }
    }
  }
}
