@import "../../design-tokens/scss-variables";

$iconSizeMobile: 24px;
$iconSizeB1: 24px;
$iconSizeB2: 24px;
$iconSizeB3: 30px;
$iconSizeB4: 30px;
$iconSizeB5: 30px;
$iconSizeB6: 30px;

.ContactSection {
  display: flex;
  justify-content: center;
  width: 100%;
  height: $navBarHeight;

  background-color: var(--color-tertiary);

  .Contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h4 {
      color: white;
      line-height: 150%;
      text-align: center;
      font-weight: 400;
    }

    .ContactLinks {
      margin: 0 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 40%;

      .ContactLink {
        img {
          width: $iconSizeMobile;
          height: $iconSizeMobile;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

@media (min-width: $screenWidthB1) {
  .ContactSection {
    .Contact {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
      h4 {
        text-align: left;
      }
      .ContactLinks {
        max-width: 30%;

        .ContactLink {
          img {
            /* Display & Box Model */
            width: $iconSizeB1;
            height: $iconSizeB1;
          }
        }
      }
    }
  }
}

@media (min-width: $screenWidthB2) {
  .ContactSection {
    .Contact {
      .ContactLinks {
        .ContactLink {
          img {
            /* Display & Box Model */
            width: $iconSizeB2;
            height: $iconSizeB2;
          }
        }
      }
    }
  }
}

@media (min-width: $screenWidthB3) {
  .ContactSection {
    .Contact {
      .ContactLinks {
        .ContactLink {
          img {
            width: $iconSizeB3;
            height: $iconSizeB3;
          }
        }
      }
    }
  }
}

@media (min-width: $screenWidthB4) {
  .ContactSection {
    .Contact {
      .ContactLinks {
        max-width: 25%;

        .ContactLink {
          img {
            width: $iconSizeB4;
            height: $iconSizeB4;
          }
        }
      }
    }
  }
}

@media (min-width: $screenWidthB5) {
  .ContactSection {
    .Contact {
      .ContactLinks {
        .ContactLink {
          img {
            width: $iconSizeB5;
            height: $iconSizeB5;
          }
        }
      }
    }
  }
}

@media (min-width: $screenWidthB6) {
  .ContactSection {
    .Contact {
      .ContactLinks {
        .ContactLink {
          img {
            width: $iconSizeB6;
            height: $iconSizeB6;
          }
        }
      }
    }
  }
}
