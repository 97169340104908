@import "../../design-tokens/scss-variables.scss";

.Projects {
  /* Display & Box Model */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto;

  .ProjectsContainer {
    /* Display & Box Model */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  h3 {
    // width: 100%;
    margin: 12px 0;
    margin-bottom: 32px;
  }

  .Projects a {
    text-decoration: none;
  }
}

@media (min-width: $screenWidthB1) {
  .Projects {
    /* Display & Box Model */
    width: $contentMaxWidthB1;

    h3 {
      /* Display & Box Model */
      width: 100%;
      // padding-left: 20px;

      /* Other */
      text-align: left;
    }
  }
}