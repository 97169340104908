@import "../../../design-tokens/scss-variables";

.Project {
  /* Display & Box Model */
  width: 100%;
  margin-bottom: 40px;
  border-radius: 20px;
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 20px;
  justify-items: center;
  transition: all 0.3s ease-out;
  padding: 40px;
  border: 2px solid rgba(100, 100, 100, 0.1);
  background-color: #637fcd11;

  .ProjectImage {
    height: 200px;
    width: 100%;
    a {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }

  .ProjectDescription {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h4 {
      margin-bottom: $titlePaddingBottomMobile;
    }

    p {
      margin-bottom: $titlePaddingBottomMobile;
    }

    .pills {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: $titlePaddingBottomMobile;
    }
  }

  .ProjectLinks {
    display: grid;
    justify-content: center;
    align-content: center;

    gap: 20px;
    grid-auto-flow: column;

    img {
      width: 24px;
      height: 24px;
      margin: 8px 0;
    }
  }
}

@media (min-width: $screenWidthB1) {
  .Project {
  .ProjectImage {
    filter: grayscale(100);
  }

  .ProjectImage:hover {
    filter: grayscale(0);
    transition: all 300ms ease-in-out;
  }
    .ProjectDescription {
      h4 {
        margin-bottom: $titlePaddingBottomMobile;
      }

      p {
        margin-bottom: $titlePaddingBottomMobile;
      }

      .pills {
        margin-right: -8px;
        margin-bottom: $titlePaddingBottomMobile;
      }
    }

    .ProjectLinks {
      img {
        width: 24px;
        height: 24px;

        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.4);
          cursor: pointer;
        }
      }
    }

    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.005) !important;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
      transform: translateZ(0);
      backface-visibility: hidden;
    }
  }
}

@media (min-width: $screenWidthB2) {
  .Project {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    margin-bottom: 40px;      
    .ProjectImage {
      height: 340px;
    }
    .ProjectDescription {
      grid-column-start: 1;
      grid-row-start: 1;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      text-align: left;

      h4 {
        margin-bottom: $titlePaddingBottomMobile;
      }

      p {
        margin-bottom: $titlePaddingBottomMobile;
      }

      .pills {
        margin-right: -8px;
        margin-bottom: $titlePaddingBottomMobile;
      }
    }

    .ProjectLinks {
      justify-content: flex-start;

      img {
        width: 24px;
        height: 24px;

        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.4);
          cursor: pointer;
        }
      }
    }

    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.005) !important;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
      transform: translateZ(0);
      backface-visibility: hidden;
    }
  }
  .Project {
    .ProjectDescription {
      h4 {
        margin-bottom: $titlePaddingBottomB2;
      }

      p {
        width: 80%;
        margin-bottom: $titlePaddingBottomB2;
      }

      .pills {
        margin-bottom: $titlePaddingBottomB2;
        margin-right: 16px;
      }
    }

    .ProjectLinks {
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
}

@media (min-width: $screenWidthB3) {
  .Project {
    .ProjectDescription {
      h4 {
        margin-bottom: $titlePaddingBottomB2;
      }

      p {
        width: 80%;
        margin-bottom: $titlePaddingBottomB2;
      }

      .pills {
        margin-bottom: $titlePaddingBottomB2;
        margin-right: 16px;
      }
    }

    .ProjectLinks {
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
}

@media (min-width: $screenWidthB4) {
  .Project {
    .ProjectDescription {
      h4 {
        margin-bottom: $titlePaddingBottomB4;
      }

      p {
        width: 80%;
        margin-bottom: $titlePaddingBottomB4;
      }

      .pills {
        margin-right: 16px;
        margin-bottom: $titlePaddingBottomB4;
      }
    }

    .ProjectLinks {
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
}

@media (min-width: $screenWidthB5) {
  .Project {
    margin-bottom: 60px;

    .ProjectDescription {
      h4 {
        margin-bottom: $titlePaddingBottomB4;
      }

      p {
        margin-bottom: $titlePaddingBottomB4;
      }

      .pills {
        margin-bottom: $titlePaddingBottomB4;
        margin-right: 16px;
      }
    }

    .ProjectLinks {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media (min-width: $screenWidthB6) {
  .Project {
    .ProjectDescription {
      h4 {
        margin-bottom: $titlePaddingBottomB4;
      }

      p {
        margin-bottom: $titlePaddingBottomB4;
      }

      .pills {
        margin-bottom: $titlePaddingBottomB4;
      }
    }

    .ProjectLinks {
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
