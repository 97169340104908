@import "./design-tokens/_scss-variables.scss";

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.content {
  width: $contentMaxWidthMobile;
}

h1 {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-h1-mobile);
  font-weight: 700;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
}

h2 {
  font-size: var(--font-size-h2-mobile);
  font-family: var(--font-family-primary-light);
  font-weight: 700;
  padding: 0;
  margin: 0;
}

h3 {
  font-size: var(--font-size-h3-mobile);
  font-family: var(--font-family-primary);
  font-weight: 700;
  padding: 0;
  margin: 0;
}

h4 {
  font-size: var(--font-size-h4-mobile);
  font-family: var(--font-family-primary);
  font-weight: 700;
  letter-spacing: -0.5px;
  padding: 0;
  margin: 0;
}

p {
  font-size: var(--font-size-p1-mobile);
  line-height: 140%;
  font-family: var(--font-family-primary-light);
  padding: 0;
  margin: 0;
}

@media (min-width: $screenWidthB1) {
  .content {
    width: $contentMaxWidthB1;
    max-width: $contentMaxWidthB1;
  }
  @include global-fonts(
    var(--font-size-h1-b1),
    var(--font-size-h2-b1),
    var(--font-size-h3-b1),
    var(--font-size-h4-b1),
    var(--font-size-p1-b1)
  );
}

@media (min-width: $screenWidthB2) {
  .content {
    width: $contentMaxWidthB2;
    max-width: $contentMaxWidthB2;
  }
  @include global-fonts(
    var(--font-size-h1-b2),
    var(--font-size-h2-b2),
    var(--font-size-h3-b2),
    var(--font-size-h4-b2),
    var(--font-size-p1-b2)
  );
}

@media (min-width: $screenWidthB3) {
  .content {
    width: $contentMaxWidthB3;
    max-width: $contentMaxWidthB3;
  }

  @include global-fonts(
    var(--font-size-h1-b3),
    var(--font-size-h2-b3),
    var(--font-size-h3-b3),
    var(--font-size-h4-b3),
    var(--font-size-p1-b3)
  );
}

@media (min-width: $screenWidthB4) {
  .content {
    width: $contentMaxWidthB4;
    max-width: $contentMaxWidthB4;
  }
  @include global-fonts(
    var(--font-size-h1-b4),
    var(--font-size-h2-b4),
    var(--font-size-h3-b4),
    var(--font-size-h4-b4),
    var(--font-size-p1-b4)
  );
}

@media (min-width: $screenWidthB5) {
  .content {
    width: $contentMaxWidthB5;
    max-width: $contentMaxWidthB5;
  }
  @include global-fonts(
    var(--font-size-h1-b5),
    var(--font-size-h2-b5),
    var(--font-size-h3-b5),
    var(--font-size-h4-b5),
    var(--font-size-p1-b5)
  );
}

@media (min-width: $screenWidthB6) {
  .content {
    width: $contentMaxWidthB6;
    max-width: $contentMaxWidthB6;
  }
  @include global-fonts(
    var(--font-size-h1-b6),
    var(--font-size-h2-b6),
    var(--font-size-h3-b6),
    var(--font-size-h4-b6),
    var(--font-size-p1-b6)
  );
}
