$avatarSizeMobile: 160px;
$avatarSizeB1: 180px;
$avatarSizeB2: 200px;
$avatarSizeB3: 240px;
$avatarSizeB4: 280px;
$avatarSizeB5: 320px;
$avatarSizeB6: 360px;

@mixin set-avatar-size($avatar-size) {
  .header-section {
    .avatar-container {
      width: $avatar-size;
      height: $avatar-size;
    }
  }
}

.about-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 40vh;
  padding-top: 90px;

  .header-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .avatar-container {
      width: $avatarSizeMobile;
      height: $avatarSizeMobile;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .header-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 40px;

      text-align: center;
    }
  }
}

@media (min-width: 681px) {
  .about-section {
    justify-content: center;
    height: 50vh;
    padding-top: 100px;

    .header-section {
      /* Positioning */
      position: relative;

      flex-direction: row;
      width: 100%;
      height: 40vh;

      .avatar-container {
        width: $avatarSizeB1;
        height: $avatarSizeB1;
      }

      .header-title {
        margin-top: 20px;
        text-align: left;
        
      }
    }
  }
}

@media (min-width: 801px) {
  .about-section {
    @include set-avatar-size($avatarSizeB2);
  }
}

@media (min-width: 1001px) {
  .about-section {
    @include set-avatar-size($avatarSizeB3);
  }
}

@media (min-width: 1201px) {
  .about-section {
    @include set-avatar-size($avatarSizeB4);
  }
}

@media (min-width: 1401px) {
  .about-section {
    @include set-avatar-size($avatarSizeB5);
  }
}

@media (min-width: 1920px) {
  .about-section {
    @include set-avatar-size($avatarSizeB6);
  }
}
