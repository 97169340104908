@import "../../design-tokens/scss-variables";

$navLinkFontSizeMobile: 10px;
$navLinkFontSizeB1: 12px;
$navLinkFontSizeB2: 16px;
$navLinkFontSizeB3: 18px;
$navLinkFontSizeB4: 20px;
$navLinkFontSizeB5: 22px;
$navLinkFontSizeB6: 24px;

.NavWrapper {
  /* Positioning */
  position: fixed;
  z-index: 2;

  /* Display & Box Model */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: $navBarHeight;

  /* Other */
  background-color: white;
  
  &--shadow {
    box-shadow: 1px 1px 4px rgb(190, 190, 190);
    transition: box-shadow 100ms ease-in;
  }

  .NavBar {
    /* Display & Box Model */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: $navBarHeight;

    .NameHeader {
      p {
        /* Other */
        font-size: var(--font-size-h2-mobile);
        font-family: var(--font-family-primary);
      }
    }

    .NavLinks {
      /* Display & Box Model */
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .NavLinkWrapper {
        /* Other */
        transition: transform 0.2s ease-in-out;

        &:hover {
          /* Other */
          transform: scale(1.1);
        }

        .NavLink {
          /* Display & Box Model */
          margin-left: 20px;

          /* Other */
          text-decoration: none;
          color: inherit;
          font-family: var(--font-family-primary);
          text-transform: uppercase;
        }
        #ResumeLink {
          /* Other */
          color: var(--color-primary) !important;
        }
      }
    }
  }
}

@media (min-width: $screenWidthB1) {
  .NavWrapper {
    .NavBar {
      .NameHeader {
        p {
          /* Other */
          font-size: var(--font-size-h4-b1);
        }
      }
      .NavLinks {
        .NavLink {
          /* Other */
          font-size: $navLinkFontSizeB1;
        }
      }
    }
  }
}

@media (min-width: $screenWidthB2) {
  .NavWrapper {
    .NavBar {
      .NameHeader {
        p {
          /* Other */
          font-size: var(--font-size-h4-b2);
        }
      }
      .NavLinks {
        .NavLink {
          /* Other */
          font-size: $navLinkFontSizeB2;
        }
      }
    }
  }
}

@media (min-width: $screenWidthB3) {
  .NavWrapper {
    .NavBar {
      .NameHeader {
        p {
          /* Other */
          font-size: var(--font-size-h4-b3);
        }
      }
      .NavLinks {
        .NavLink {
          /* Other */
          font-size: $navLinkFontSizeB3;
        }
      }
    }
  }
}

@media (min-width: $screenWidthB4) {
  .NavWrapper {
    .NavBar {
      .NameHeader {
        p {
          /* Other */
          font-size: var(--font-size-h4-b4);
        }
      }
      .NavLinks {
        .NavLink {
          /* Other */
          font-size: $navLinkFontSizeB4;
        }
      }
    }
  }
}

@media (min-width: $screenWidthB5) {
  .NavWrapper {
    .NavBar {
      .NameHeader {
        p {
          /* Other */
          font-size: var(--font-size-h4-b5);
        }
      }
      .NavLinks {
        .NavLink {
          /* Other */
          font-size: $navLinkFontSizeB5;
        }
      }
    }
  }
}

@media (min-width: $screenWidthB6) {
  .NavWrapper {
    .NavBar {
      .NameHeader {
        p {
          /* Other */
          font-size: var(--font-size-h4-b6);
        }
      }
      .NavLinks {
        .NavLink {
          /* Other */
          font-size: $navLinkFontSizeB6;
        }
      }
    }
  }
}
