@import "../../design-tokens/scss-variables";
.LanguagePill {
  /* Display & Box Model */
  display: grid;
  place-items: center;
  margin-right: 8px;
  border-radius: 20px;
  padding: 6px 12px 4px 12px;

  p {
    /* Other */
    margin: 0 0 !important;
    padding: 0 !important;
    width: 100% !important;
    color: white;
    font-size: var(--font-size-p2-mobile);
  }
}

@media (min-width: $screenWidthB1) {
  .LanguagePill {
    margin-right: 8px;
    padding: 6px 8px 3px 8px;
    p {
      font-size: var(--font-size-p2-b1);
    }
  }
}

@media (min-width: $screenWidthB2) {
  .LanguagePill {
    margin-right: 8px;
    border-radius: 20px;
    padding: 6px 8px 3px 8px;
    p {
      font-size: var(--font-size-p2-b2);
    }
  }
}

@media (min-width: $screenWidthB3) {
  .LanguagePill {
    margin-right: 12px;
    border-radius: 20px;
    padding: 6px 12px 3px 12px;
    p {
      font-size: var(--font-size-p2-b3);
    }
  }
}

@media (min-width: $screenWidthB4) {
  .LanguagePill {
    margin-right: 12px;
    border-radius: 20px;
    padding: 6px 12px 3px 12px;
    p {
      font-size: var(--font-size-p2-b4);
    }
  }
}

@media (min-width: $screenWidthB5) {
  .LanguagePill {
    margin-right: 16px;
    border-radius: 20px;
    padding: 6px 16px 3px 16px;
    p {
      font-size: var(--font-size-p2-b5);
    }
  }
}

@media (min-width: $screenWidthB6) {
  .LanguagePill {
    margin-right: 16px;
    padding: 6px 20px 3px 20px;
    border-radius: 40px;
    p {
      font-size: var(--font-size-p2-b6);
    }
  }
}
